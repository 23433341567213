// custom js
var school = {
    
	init: function() {
		
        school.controls();
        school.tabs();
        
        new Clipboard('.copy');
        
	},
    
    controls: function() {
        
        $('.nav-toggle').click(function() {
            $(this).toggleClass("active");
            $(".nav").toggleClass("active");
        });
        
        
        $('.banners').slick({
            arrows:true,
            dots: false,
            prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>'
        });
        
        
        $('.gallery-carousel').find('.slides').slick({
            arrows:true,
            dots: true,
            prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>'
        });
        
        
        $('.gallery-lightbox').find('.slides').slick({
            arrows:true,
            dots: true,
            slidesToShow: 3,
            variableWidth: true,
            centerMode: true,
            prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>'
        });
        
    },
    
    tabs: function() {
        
        $('.tab').each(function(index, tab) {
         
            $(tab).click(function(e) {
                
                e.preventDefault();
                
                var tabs = $(tab).closest('.tabs');
                
                $(tabs).find('.tab').removeClass("active");
                $(tab).addClass("active");
                
                var tab_id = $(tab).attr("href");
                
                $(tabs).find('.tab-content').removeClass("active")
                $(tab).closest('.tabs').find(tab_id).addClass("active");
                
            });
          
        });
        
    } 
    
	
}